import { User } from '../../pages/Settings/Users/AllUsers/UsersList';
import { HTTP_METHOD } from '../auth/http';
import { ENDPOINTS } from '../endpoints';
import { getListProvidesTags } from '../generic';
import { OAKENApi } from '../OAKENApi';
import { ACCOUNT, REDUX_CACHE, USERS } from '../tags';

export const usersAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        getUsers: builder.query({
            query: (params) => {
                return {
                    url: process.env.REACT_APP_USER_API_HOST + ENDPOINTS.users.root,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (data: User[], meta): { data: User[]; totalCount: number } => {
                const xTotalCount = meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) => getListProvidesTags(result?.data, USERS.USERS),
        }),
        getUsersByPOST: builder.query({
            query: (payload) => {
                return {
                    url: process.env.REACT_APP_USER_API_HOST + ENDPOINTS.users.search,
                    method: HTTP_METHOD.POST,
                    body: payload.body,
                    params: payload.params,
                };
            },
            transformResponse: (data: User[], meta): { data: User[]; totalCount: number } => {
                const xTotalCount = meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) => getListProvidesTags(result?.data, USERS.USERS),
        }),
        getOneUser: builder.query({
            query: (id) => {
                return {
                    url: process.env.REACT_APP_USER_API_HOST + ENDPOINTS.users.root + `/${id}`,
                    method: HTTP_METHOD.GET,
                };
            },
        }),
        addUser: builder.mutation({
            query: (payload) => ({
                url: process.env.REACT_APP_USER_API_HOST + ENDPOINTS.users.root,
                method: HTTP_METHOD.POST,
                body: payload,
            }),
            invalidatesTags: [USERS.USERS],
        }),
        updateUser: builder.mutation({
            query: (body) => {
                return {
                    url: process.env.REACT_APP_USER_API_HOST + ENDPOINTS.users.root + `/${body.id}`,
                    method: HTTP_METHOD.PUT,
                    body,
                };
            },
            invalidatesTags: (result, error, arg) => [
                { type: USERS.USERS, id: REDUX_CACHE.LIST },
                { type: USERS.USERS, id: arg },
                { type: ACCOUNT.ACCOUNT },
            ],
        }),
        deleteUser: builder.mutation({
            query: (id) => {
                return {
                    url: process.env.REACT_APP_USER_API_HOST + `${ENDPOINTS.users.root}/${id}`,
                    method: HTTP_METHOD.DELETE,
                };
            },
            invalidatesTags: (result, error, params) => {
                if (error) {
                    return [REDUX_CACHE.ABORT_INVALIDATION] as unknown as [];
                }
                return [
                    {
                        type: USERS.USERS,
                        id: REDUX_CACHE.LIST,
                    },
                    {
                        type: USERS.USERS,
                        id: params.id,
                    },
                ];
            },
        }),
        profilePhoto: builder.query({
            query: (id) => {
                return {
                    url: process.env.REACT_APP_USER_API_HOST + ENDPOINTS.users.photo + `/${id}`,
                    method: HTTP_METHOD.GET,
                };
            },
        }),
        getUsersCurrentOrg: builder.query({
            query: (params) => {
                return {
                    url: process.env.REACT_APP_USER_API_HOST + ENDPOINTS.users.currentOrganization,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
        }),
    }),
});

export const {
    useGetUsersCurrentOrgQuery,
    useLazyProfilePhotoQuery,
    useGetUsersQuery,
    useLazyGetUsersQuery,
    useGetUsersByPOSTQuery,
    useLazyGetUsersByPOSTQuery,
    useLazyGetOneUserQuery,
    useAddUserMutation,
    useUpdateUserMutation,
    useDeleteUserMutation,
} = usersAPI;
