import { HTTP_METHOD } from '../auth/http';
import { ENDPOINTS } from '../endpoints';
import { OAKENApi } from '../OAKENApi';
import { CUSTOMIZE_CONFIG, LEASES } from '../tags';

export interface CustomizedView {
    id: string;
    viewType: ViewType;
    viewSettings: ViewSettings;
}

export type ViewType =
    | 'LEASES'
    | 'LAND_PARTNERS'
    | 'ALL_CONTACTS'
    | 'PAYMENTS'
    | 'PAYMENT_SCHEDULED'
    | 'PAYMENT_PAID'
    | 'GROWERS'
    | 'TASKS';

export interface ViewSettings {
    table: ColumnConfig[];
    freezeColumns: boolean;
}

export interface ColumnConfig {
    id: string;
    text: string;
    isHidden: boolean;
}

export const customizeConfigAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        getOneConfig: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.customizeConfig.customizeView,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
        }),
        addConfig: builder.mutation({
            query: (payload) => ({
                url: process.env.REACT_APP_USER_API_HOST + ENDPOINTS.customizeConfig.customizeView,
                method: HTTP_METHOD.POST,
                body: payload,
            }),
            invalidatesTags: () => [
                { type: LEASES.LEASES },
                { type: CUSTOMIZE_CONFIG.CUSTOMIZE_CONFIG },
            ],
        }),
        updateConfig: builder.mutation({
            query: (body) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.customizeConfig.customizeView,
                    method: HTTP_METHOD.PUT,
                    body,
                };
            },
            invalidatesTags: () => [
                { type: LEASES.LEASES },
                { type: CUSTOMIZE_CONFIG.CUSTOMIZE_CONFIG },
            ],
        }),
    }),
});

export const {
    useGetOneConfigQuery,
    useLazyGetOneConfigQuery,
    useAddConfigMutation,
    useUpdateConfigMutation,
} = customizeConfigAPI;
