import React from 'react';

import { Autocomplete as MuiAutocomplete, FormControl } from '@mui/material';
import TextField from '@mui/material/TextField';

import { makeStyles } from 'tss-react/mui';

import { AutocompleteOption } from './AutocompleteField';

export interface SearchAutocomplete {
    onChange: (value: AutocompleteOption | string | null) => void;
    onClick?: () => void;
    placeholder?: string;
    isDisabled?: boolean;
    errorText?: string;
    isLoading?: boolean;
    name?: string;
    size?: 'small' | 'medium';
    freeSolo?: boolean;
    value?: AutocompleteOption | string | null;
    options?: AutocompleteOption[];
}

export default function SearchAutocompleteField({
    value,
    size,
    errorText,
    onChange,
    placeholder,
    isDisabled,
    name,
    onClick,
    isLoading,
    freeSolo = false,
    options = [],
}: SearchAutocomplete) {
    const { classes } = useStyles();

    return (
        <FormControl
            variant="outlined"
            size={size}
            fullWidth
            error={!!errorText}
            disabled={isDisabled}>
            <MuiAutocomplete
                loading={isLoading}
                onClick={onClick}
                id={name}
                freeSolo={freeSolo}
                disabled={isDisabled}
                autoSelect
                filterSelectedOptions
                getOptionKey={(option: string | AutocompleteOption) =>
                    typeof option === 'string' ? option : option.id
                }
                options={options.map((option) => {
                    return {
                        ...option,
                        id: option.id,
                        name: option.name,
                    };
                })}
                getOptionLabel={(option: AutocompleteOption | string) =>
                    typeof option === 'string' ? option : option.name
                }
                onChange={(_, value: AutocompleteOption | string | null) => {
                    onChange(value);
                }}
                value={value || null}
                isOptionEqualToValue={(option, value) =>
                    typeof option === 'string'
                        ? option === value
                        : option.id === (typeof value === 'string' ? value : value.id)
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={placeholder}
                        variant="outlined"
                        size={size}
                        error={!!errorText}
                        helperText={errorText}
                        InputProps={{
                            ...params.InputProps,
                            classes: {
                                root: classes.inputRoot,
                                input: classes.input,
                            },
                        }}
                        InputLabelProps={{
                            className: classes.placeholder,
                        }}
                    />
                )}
                classes={{
                    inputRoot: classes.inputRoot,
                    tag: classes.chipRoot,
                }}
            />
        </FormControl>
    );
}

const useStyles = makeStyles()(() => ({
    inputRoot: {
        minHeight: '38.99px !important',
        padding: '2px !important',
    },
    input: {
        padding: '4px 6px !important',
        height: 'initial',
    },
    placeholder: {
        fontSize: '14px',
    },
    chipRoot: {
        height: '26px !important',
        fontSize: '14px !important',
        margin: '1px !important',
    },
}));
