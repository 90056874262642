import { Notification, NotificationType } from '../../features/Notifications/NotificationCard';
import { HTTP_METHOD } from '../auth/http';
import { ENDPOINTS } from '../endpoints';
import { getListProvidesTags } from '../generic';
import { OAKENApi } from '../OAKENApi';
import { CALENDAR, DASHBOARD, NOTIFICATIONS } from '../tags';

export interface UpcomingEventResponse {
    eventText: string;
    notificationType: NotificationType;
    eventDate: string;
}

interface ShareDataPreviewParams {
    landPartnerId: string;
    visibilityLevel: string;
}

export const notificationAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        getNotificationsSettings: builder.query({
            query: (organizationId) => {
                return {
                    url:
                        process.env.REACT_APP_NOTIFICATION_API_HOST +
                        ENDPOINTS.notifications.settings +
                        `/${organizationId}`,
                    method: HTTP_METHOD.GET,
                };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, NOTIFICATIONS.NOTIFICATIONS),
        }),
        getUserNotificationsSettings: builder.query({
            query: () => {
                return {
                    url:
                        process.env.REACT_APP_NOTIFICATION_API_HOST +
                        ENDPOINTS.notifications.settings,
                    method: HTTP_METHOD.GET,
                };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, NOTIFICATIONS.NOTIFICATIONS),
        }),
        updateNotificationsSettings: builder.mutation({
            query: ({ id, data }) => {
                return {
                    url:
                        process.env.REACT_APP_NOTIFICATION_API_HOST +
                        ENDPOINTS.notifications.settings +
                        `/${id}`,
                    method: HTTP_METHOD.PATCH,
                    body: data,
                };
            },
            invalidatesTags: (result, error, arg) => [
                { type: NOTIFICATIONS.NOTIFICATIONS, id: arg },
                { type: NOTIFICATIONS.NOTIFICATIONS },
            ],
        }),
        updateUserNotificationsSettings: builder.mutation({
            query: ({ data }) => {
                return {
                    url:
                        process.env.REACT_APP_NOTIFICATION_API_HOST +
                        ENDPOINTS.notifications.settings,
                    method: HTTP_METHOD.PUT,
                    body: data,
                };
            },
            invalidatesTags: (result, error, arg) => [
                { type: NOTIFICATIONS.NOTIFICATIONS, id: arg },
                { type: NOTIFICATIONS.NOTIFICATIONS },
            ],
        }),
        getNotificationsInfo: builder.query({
            query: () => {
                return {
                    url: process.env.REACT_APP_NOTIFICATION_API_HOST + ENDPOINTS.notifications.info,
                    method: HTTP_METHOD.GET,
                };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, NOTIFICATIONS.NOTIFICATIONS_INFO),
        }),
        getInfiniteNotificationsList: builder.query({
            query: (params) => {
                return {
                    url: process.env.REACT_APP_NOTIFICATION_API_HOST + ENDPOINTS.notifications.root,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },

            transformResponse: (
                data: Notification[],
                meta,
            ): { data: Notification[]; totalCount: number } => {
                const xTotalCount = meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },

            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                currentCache.totalCount = newItems.totalCount;
                currentCache.data.push(...newItems.data);
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;
            },

            providesTags: (result) => {
                return result
                    ? [
                          ...result.data.map(({ id }) => ({
                              type: NOTIFICATIONS.NOTIFICATIONS as const,
                              id,
                          })),
                          NOTIFICATIONS.NOTIFICATIONS,
                      ]
                    : [NOTIFICATIONS.NOTIFICATIONS];
            },
        }),
        getNotificationsList: builder.query({
            query: (params) => {
                return {
                    url: process.env.REACT_APP_NOTIFICATION_API_HOST + ENDPOINTS.notifications.root,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (
                data: Notification[],
                meta,
            ): { data: Notification[]; totalCount: number } => {
                const xTotalCount = meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, NOTIFICATIONS.NOTIFICATIONS),
        }),
        readNotifications: builder.mutation({
            query: (ids) => {
                return {
                    url: process.env.REACT_APP_NOTIFICATION_API_HOST + ENDPOINTS.notifications.read,
                    method: HTTP_METHOD.PUT,
                    body: ids,
                };
            },
            invalidatesTags: () => [{ type: NOTIFICATIONS.NOTIFICATIONS_INFO }],
        }),
        deleteNotification: builder.mutation({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_NOTIFICATION_API_HOST +
                        ENDPOINTS.notifications.root +
                        `/${id}`,
                    method: HTTP_METHOD.DELETE,
                };
            },
            invalidatesTags: (result, error, id) => [
                { type: NOTIFICATIONS.NOTIFICATIONS, id },
                { type: NOTIFICATIONS.NOTIFICATIONS, id: 'PARTIAL-LIST' },
            ],
        }),
        snoozeNotification: builder.mutation({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_NOTIFICATION_API_HOST +
                        ENDPOINTS.notifications.snooze +
                        `/${id}`,
                    method: HTTP_METHOD.POST,
                };
            },
            invalidatesTags: () => [{ type: NOTIFICATIONS.NOTIFICATIONS }],
        }),
        notificationVisibility: builder.mutation({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_NOTIFICATION_API_HOST +
                        ENDPOINTS.notifications.root +
                        `/${id}/dashboard-visibility`,
                    method: HTTP_METHOD.PATCH,
                };
            },
            invalidatesTags: () => [
                { type: NOTIFICATIONS.NOTIFICATIONS },
                { type: DASHBOARD.DASHBOARD },
            ],
        }),
        sendEcardById: builder.mutation({
            query: ({ notificationId, ...body }) => {
                return {
                    url:
                        process.env.REACT_APP_NOTIFICATION_API_HOST +
                        ENDPOINTS.notifications.sendEcard +
                        `/${notificationId}`,
                    method: HTTP_METHOD.POST,
                    body,
                };
            },
            invalidatesTags: [CALENDAR.CALENDAR, DASHBOARD.DASHBOARD, NOTIFICATIONS.NOTIFICATIONS],
        }),
        sendEcardByContactId: builder.mutation({
            query: (body) => {
                return {
                    url: process.env.REACT_APP_NOTIFICATION_API_HOST + ENDPOINTS.calendar.sendEcard,
                    method: HTTP_METHOD.POST,
                    body,
                };
            },
            invalidatesTags: [CALENDAR.CALENDAR, DASHBOARD.DASHBOARD, NOTIFICATIONS.NOTIFICATIONS],
        }),
        getUpcomingEventsPermanent: builder.query<UpcomingEventResponse[], void>({
            query: () => {
                return {
                    url:
                        process.env.REACT_APP_NOTIFICATION_API_HOST +
                        ENDPOINTS.notifications.sharedData.root,
                    method: HTTP_METHOD.GET,
                };
            },
        }),
        getUpcomingEventsTemporary: builder.query<UpcomingEventResponse[], string>({
            query: (xShareDataToken) => {
                return {
                    url:
                        process.env.REACT_APP_NOTIFICATION_API_HOST +
                        ENDPOINTS.notifications.sharedData.temporary,
                    method: HTTP_METHOD.GET,
                    headers: {
                        'X-Share-Data-Token': xShareDataToken,
                    },
                };
            },
        }),
        getUpcomingEventsPreview: builder.query<UpcomingEventResponse[], ShareDataPreviewParams>({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_NOTIFICATION_API_HOST +
                        ENDPOINTS.notifications.sharedData.preview,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
        }),
    }),
});

export const {
    useSendEcardByIdMutation,
    useSendEcardByContactIdMutation,
    useNotificationVisibilityMutation,
    useLazyGetNotificationsSettingsQuery,
    useUpdateNotificationsSettingsMutation,
    useLazyGetInfiniteNotificationsListQuery,
    useGetNotificationsInfoQuery,
    useGetNotificationsListQuery,
    useLazyGetNotificationsListQuery,
    useReadNotificationsMutation,
    useDeleteNotificationMutation,
    useSnoozeNotificationMutation,
    useGetUserNotificationsSettingsQuery,
    useUpdateUserNotificationsSettingsMutation,
    useGetUpcomingEventsPreviewQuery,
    useGetUpcomingEventsPermanentQuery,
    useGetUpcomingEventsTemporaryQuery,
} = notificationAPI;
