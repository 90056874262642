import { DocumentCategory } from '../../features/ViewDashboard/RecentUploads';
import { Organization } from '../../pages/Settings/Organizations/AllOrganizations/OrganizationsList';
import { HTTP_METHOD } from '../auth/http';
import { ENDPOINTS } from '../endpoints';
import { getListProvidesTags } from '../generic';
import { OAKENApi } from '../OAKENApi';
import { ORGANIZATIONS, REDUX_CACHE } from '../tags';

interface OrganizationConfigResponse {
    documentCategories: DocumentCategory[];
    partnerServices: PartnerServicesName[];
}

interface GetOrganizationConfigByIdParams {
    organizationId: string;
}

interface OrganizationConfigRequest {
    documentCategories: DocumentCategory[];
    partnerServices: PartnerServicesName[];
}

export type PartnerServicesName = 'SOWER' | 'COMPEER' | 'NATIONWIDE' | 'CORNELL';

export const organizationsAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        getOrganizations: builder.query({
            query: (params) => {
                return {
                    url: process.env.REACT_APP_USER_API_HOST + ENDPOINTS.organizations.root,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (
                data: Organization[],
                meta,
            ): { data: Organization[]; totalCount: number } => {
                const xTotalCount = meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, ORGANIZATIONS.ORGANIZATIONS),
        }),
        getOneOrganization: builder.query({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.organizations.root +
                        `/${id}`,
                    method: HTTP_METHOD.GET,
                };
            },
        }),
        addOrganization: builder.mutation({
            query: ({ companyLogo, ...values }) => {
                const formData = new FormData();
                if (companyLogo) {
                    formData.append('landPartnerLogo', companyLogo[0]);
                }
                formData.append(
                    'request',
                    new Blob([JSON.stringify(values)], {
                        type: 'application/json',
                    }),
                );
                return {
                    url: process.env.REACT_APP_USER_API_HOST + ENDPOINTS.organizations.root,
                    method: HTTP_METHOD.POST,
                    body: formData,
                };
            },
            invalidatesTags: [ORGANIZATIONS.ORGANIZATIONS],
        }),
        updateOrganization: builder.mutation({
            query: ({ companyLogo, ...values }) => {
                const formData = new FormData();
                if (companyLogo) {
                    formData.append('landPartnerLogo', companyLogo[0]);
                }
                formData.append(
                    'request',
                    new Blob([JSON.stringify(values)], {
                        type: 'application/json',
                    }),
                );

                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.organizations.root +
                        `/${values.id}`,
                    method: HTTP_METHOD.PUT,
                    body: formData,
                };
            },
            invalidatesTags: (result, error, arg) => [
                { type: ORGANIZATIONS.ORGANIZATIONS, id: REDUX_CACHE.LIST },
                { type: ORGANIZATIONS.ORGANIZATIONS, id: arg },
            ],
        }),
        deleteOrganization: builder.mutation({
            query: (id) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.organizations.root +
                        `/${id}`,
                    method: HTTP_METHOD.DELETE,
                };
            },
            invalidatesTags: (result, error, arg) => [
                { type: ORGANIZATIONS.ORGANIZATIONS, id: REDUX_CACHE.LIST },
                { type: ORGANIZATIONS.ORGANIZATIONS, id: arg },
            ],
        }),
        getListSuborganizations: builder.query({
            query: ({ id, ...params }) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.organizations.root +
                        `/${id}/suborganizations`,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            transformResponse: (
                data: Organization[],
                meta,
            ): { data: Organization[]; totalCount: number } => {
                const xTotalCount = meta?.response?.headers.get('X-Total-Count');
                return { data, totalCount: xTotalCount ? +xTotalCount : 0 };
            },
            providesTags: (result) =>
                getListProvidesTags(result?.data, ORGANIZATIONS.ORGANIZATIONS),
        }),
        getUsersOfOrg: builder.query({
            query: ({ ...params }) => {
                return {
                    url: process.env.REACT_APP_USER_API_HOST + ENDPOINTS.users.organizationUsers,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
        }),
        orgDashboard: builder.query({
            query: (params) => {
                return {
                    url: process.env.REACT_APP_USER_API_HOST + ENDPOINTS.organizations.summary,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
        }),
        getOrganizationConfigById: builder.query<
            OrganizationConfigResponse,
            GetOrganizationConfigByIdParams
        >({
            query: ({ organizationId }) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.organizations.config.root +
                        `/${organizationId}`,
                    method: HTTP_METHOD.GET,
                };
            },
            providesTags: (result, error, { organizationId }) => [
                {
                    type: ORGANIZATIONS.ORGANIZATION_CONFIG,
                    id: organizationId,
                },
            ],
        }),
        getCurrentOrganizationConfig: builder.query<OrganizationConfigResponse, void>({
            query: () => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.organizations.config.current,
                    method: HTTP_METHOD.GET,
                };
            },
            providesTags: () => [
                {
                    type: ORGANIZATIONS.ORGANIZATION_CONFIG,
                    id: REDUX_CACHE.LIST,
                },
            ],
        }),
        saveOrganizationConfig: builder.mutation<
            void,
            { organizationId: string; requestBody: OrganizationConfigRequest }
        >({
            query: ({ organizationId, requestBody }) => ({
                url:
                    process.env.REACT_APP_USER_API_HOST +
                    ENDPOINTS.organizations.config.root +
                    `/${organizationId}`,
                method: HTTP_METHOD.PUT,
                body: requestBody,
            }),
            invalidatesTags: (result, error, { organizationId }) => [
                {
                    type: ORGANIZATIONS.ORGANIZATION_CONFIG,
                    id: organizationId,
                },
            ],
        }),
        potentialClients: builder.query({
            query: (params) => {
                return {
                    url:
                        process.env.REACT_APP_USER_API_HOST +
                        ENDPOINTS.organizations.potentialClients,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
        }),
        getGrowersList: builder.query({
            query: (params) => {
                return {
                    url: process.env.REACT_APP_USER_API_HOST + ENDPOINTS.organizations.growers,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
        }),
    }),
});

export const {
    useLazyGetGrowersListQuery,
    usePotentialClientsQuery,
    useGetOrganizationsQuery,
    useOrgDashboardQuery,
    useLazyGetOrganizationsQuery,
    useLazyGetOneOrganizationQuery,
    useGetUsersOfOrgQuery,
    useLazyGetUsersOfOrgQuery,
    useDeleteOrganizationMutation,
    useUpdateOrganizationMutation,
    useAddOrganizationMutation,
    useGetListSuborganizationsQuery,
    useGetOrganizationConfigByIdQuery,
    useGetCurrentOrganizationConfigQuery,
    useSaveOrganizationConfigMutation,
} = organizationsAPI;
