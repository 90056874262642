import { HTTP_METHOD } from '../auth/http';
import { ENDPOINTS } from '../endpoints';
import { OAKENApi } from '../OAKENApi';
import { SEARCH } from '../tags';

export interface FilteredResultItem {
    entityId: string;
    name: string;
    type: 'PROFILE' | 'LEASE' | 'LEASE_LAND';
    createdBy: string;
    updatedAt: string;
    baseClass: string;
    noteOwner: string;
    leaseExpired: boolean;
}
interface FilteredResultQueryParams {
    value: string;
    [key: string]: string | number | boolean;
}

interface FilteredResultResponse extends Array<FilteredResultItem> {}
export const searchAPI = OAKENApi.injectEndpoints({
    endpoints: (builder) => ({
        searchResult: builder.query({
            query: (value) => {
                return {
                    url:
                        process.env.REACT_APP_SEARCH_API_HOST +
                        `${ENDPOINTS.search.root}?prompt=${value}`,
                    method: HTTP_METHOD.GET,
                };
            },
            providesTags: (result) => {
                if (!result) return [];
                return [{ type: SEARCH.SEARCH, id: 'list' }];
            },
        }),
        filteredResult: builder.query<FilteredResultResponse, FilteredResultQueryParams>({
            query: ({ value, ...params }) => {
                return {
                    url:
                        process.env.REACT_APP_SEARCH_API_HOST +
                        `${ENDPOINTS.search.filtered}?prompt=${value}`,
                    method: HTTP_METHOD.GET,
                    params,
                };
            },
            providesTags: (result) => {
                if (!result) return [];
                return [{ type: SEARCH.SEARCH, id: 'list' }];
            },
        }),
    }),
});

export const { useLazySearchResultQuery, useLazyFilteredResultQuery } = searchAPI;
