import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { createNumberMask } from 'text-mask-addons';

const removeLeadingZeros = (conformedValue: string) => {
    const parts = conformedValue.split('.');

    if (parts[0]) {
        parts[0] = parts[0].replace(/^0+(?=\d)/, '');
        if (parts[0] === '') parts[0] = '0';
    }

    return parts.length > 1 ? parts.join('.') : parts[0];
};

export const currencyMask = createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 9,
    allowNegative: false,
    allowLeadingZeroes: false,
});

currencyMask.pipe = (conformedValue: string) => {
    if (!conformedValue) return '';
    return removeLeadingZeros(conformedValue);
};

export const numberMask = createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 26,
    allowNegative: false,
    allowLeadingZeroes: false,
});

numberMask.pipe = (conformedValue: string) => {
    if (!conformedValue) return '';
    return removeLeadingZeros(conformedValue);
};

export const formatCurrency = (value?: string | number) => {
    return value
        ? new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
          }).format(+value)
        : '$0.00';
};
export const formatNumber = (value?: string | number) => {
    return value
        ? new Intl.NumberFormat('en-US', {
              maximumFractionDigits: 2,
          }).format(+value)
        : '0';
};

export const phoneMaskForString = (value: string): string | undefined => {
    const phoneNumber = parsePhoneNumberFromString(`+${value}`, 'US');
    if (!phoneNumber) {
        console.error(`Invalid phone number: ${value}`);
        return undefined;
    }
    const internationalFormat = phoneNumber.format('INTERNATIONAL');
    const nationalFormat = phoneNumber.format('NATIONAL');
    const countryCode = phoneNumber.countryCallingCode;
    const country = phoneNumber.country;

    if (countryCode) {
        return country === 'US' ? `+1 ${nationalFormat}` : ` ${internationalFormat}`;
    }
    return undefined;
};

export const formatCurrencyOnBlur = (value: string): string => {
    if (!value) return '';

    const numericValue = value.replace(/[^\d.]/g, '');
    const parsed = parseFloat(numericValue);

    if (isNaN(parsed)) return '';

    return parsed.toFixed(2);
};

export const formatNumberOnBlur = (value: string): string => {
    if (!value) return '';

    const numericValue = value.replace(/[^\d.]/g, '');

    if (!/^\d*\.?\d*$/.test(numericValue)) return '';

    const parts = numericValue.split('.');

    if (parts[0]) {
        parts[0] = parts[0].replace(/^0+(?=\d)/, '');
        if (parts[0] === '') parts[0] = '0';
    }

    const decimalPart = parts.length > 1 ? parts[1].substring(0, 2) : '';

    return parts[0] + (decimalPart ? '.' + decimalPart : '');
};
