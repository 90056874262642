import React from 'react';

import { Grid, Stack, Typography } from '@mui/material';

import { useGetUserNotificationsSettingsQuery } from '../../../api/notifications/notificationAPI';
import NotificationsSettingsList from './NotificationsSettingsList';

export default function UserNotificationsSettings() {
    const {
        data: notificationsData,
        isLoading,
        isFetching,
    } = useGetUserNotificationsSettingsQuery({});

    return (
        <Stack spacing={2}>
            <Typography variant="font20">Notifications</Typography>
            <Grid container flexDirection={'column'} width={'100%'}>
                <NotificationsSettingsList
                    notificationsData={notificationsData}
                    isLoading={isLoading || isFetching}
                />
            </Grid>
        </Stack>
    );
}
