import React, {
    ComponentType,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import {
    Box,
    Table as MuiTable,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material';
import { PaletteOptions } from '@mui/material/styles/createPalette';

import { makeStyles } from 'tss-react/mui';

import usePagination from './usePagination';

import NotesImage from '../../assets/images/oaken_notes.png';
import EmptyList from '../../reusable/EmptyList';
import palette from '../../styles/palette';
import TableSkeleton from '../Feedback/TableSkeleton';
import GenericTableHeader from './GenericTableHeader';
import { TableHeaderCell } from './GenericTableHeaderCell';
import GenericTableRow from './GenericTableRow';
import TablePagination from './TablePagination';

type OrderDirection = 'asc' | 'desc';
interface ColumnFormat {
    align: 'left' | 'right' | 'center';
    color: TableColor;
}
export interface Sorting {
    direction: OrderDirection;
    isSorted?: boolean;
    id: string;
}
export interface TableColumn<T> {
    header?: TableHeaderCell;
    cellRender: (data: T) => ReactNode;
    format?: ColumnFormat;
    width?: string | number;
    isHidden?: boolean;
    maxColumnWidth?: string | number;
}
interface TableColor {
    color: keyof typeof palette | PaletteOptions;
}
export interface Table<T> {
    columns: TableColumn<T>[];
    expandable?: boolean;
    pageable?: boolean;
    stickyHeader?: boolean;
    handlePageChange?: (item: number) => void;
    handleItemsPerPageChange?: (item: number) => void;
    ExpandComponent?: ComponentType<{ data: T }>;
    onClick?: (data: T) => void;
    filter?: object;
    multiselect?: boolean;
    isSelected?: (item: T) => boolean;
    toggleSelection?: (item: T) => void;
    toggleSelectionAll?: (items: T[]) => void;
    isSelectedAll?: (items: T[]) => boolean;
    initialPageSize?: number;
    initialPage?: number;
}
interface TableProps<T> {
    data: T[];
    isLoading?: boolean;
    isHidden?: boolean;
    totalCount?: number;
    tableConfig: Table<T>;
}

export default function GenericTable<T>({
    data = [],
    totalCount = 0,
    isLoading = false,
    isHidden = false,
    tableConfig,
}: TableProps<T>) {
    const {
        columns,
        pageable = true,
        handlePageChange,
        handleItemsPerPageChange,
        ExpandComponent,
        multiselect,
        expandable,
        isSelected,
        toggleSelection,
        toggleSelectionAll,
        isSelectedAll,
        initialPageSize,
        initialPage,
        stickyHeader = false,
        onClick,
    } = tableConfig;
    const [maxHeight, setMaxHeight] = useState('300px');
    const tableRef = useRef<HTMLTableElement>(null);
    const { classes } = useStyles();

    useEffect(() => {
        if (stickyHeader) {
            const updateMaxHeight = () => {
                const tableTop = tableRef.current?.getBoundingClientRect().top || 0;
                const availableHeight = window.innerHeight - tableTop - 64;
                setMaxHeight(availableHeight < 300 ? '300px' : `${availableHeight}px`);
            };

            updateMaxHeight();
            window.addEventListener('resize', updateMaxHeight);

            return () => {
                window.removeEventListener('resize', updateMaxHeight);
            };
        }
    }, [stickyHeader, tableRef?.current]);

    const headerConfig = useMemo(
        () =>
            columns.map((column) => column.header).filter((header) => header) as Required<
                TableHeaderCell[]
            >,
        [columns],
    );

    const { currentPage, pageSize, handlePageSizeChange, visibleData } = usePagination(
        data,
        initialPage,
        initialPageSize,
    );

    const pageData = handleItemsPerPageChange || !pageable ? data : visibleData;

    const memoizedToggleSelectionAll = useCallback(() => {
        if (toggleSelectionAll) {
            toggleSelectionAll(pageData);
        }
    }, [toggleSelectionAll, pageData]);

    const memoizedToggleSelection = useCallback(
        (row: T) => {
            if (toggleSelection) {
                toggleSelection(row);
            }
        },
        [toggleSelection],
    );

    if (isHidden) {
        return null;
    }

    if (isLoading) {
        return <TableSkeleton />;
    }

    return (
        <TableContainer
            className={stickyHeader ? classes.stickyTableContainer : classes.tableContainer}
            sx={{ maxHeight: stickyHeader ? maxHeight : 'auto' }}
            role="table">
            <MuiTable ref={tableRef} stickyHeader={stickyHeader} aria-label="generic table">
                <GenericTableHeader
                    header={headerConfig}
                    multiselect={multiselect}
                    selected={isSelectedAll && isSelectedAll(pageData)}
                    onSelect={memoizedToggleSelectionAll}
                    expandable={expandable}
                />
                <TableBody>
                    {!pageData.length && (
                        <TableRow>
                            <TableCell colSpan={columns?.length} role="cell">
                                <EmptyList image={NotesImage} title={'No Data Available'} />
                            </TableCell>
                        </TableRow>
                    )}
                    {pageData?.map((row, rowIndex) => (
                        <GenericTableRow
                            key={rowIndex}
                            data={row}
                            columns={columns}
                            expandable={expandable}
                            ExpandComponent={ExpandComponent}
                            multiselect={multiselect}
                            selected={isSelected && isSelected(row)}
                            onSelect={() => memoizedToggleSelection(row)}
                            onClick={onClick}
                        />
                    ))}
                </TableBody>
            </MuiTable>
            {pageable && !!data.length && (
                <Box className={classes.stickyBox}>
                    <TablePagination
                        handleMovePage={handlePageSizeChange}
                        handlePageChange={handlePageChange}
                        handleItemsPerPageChange={handleItemsPerPageChange}
                        pageSize={pageSize}
                        ordersCount={totalCount}
                        page={currentPage}
                    />
                </Box>
            )}
        </TableContainer>
    );
}
const useStyles = makeStyles()(() => ({
    tableContainer: {
        maxHeight: 'auto',
        overflow: 'auto',
    },
    stickyTableContainer: {
        overflow: 'auto',
    },
    stickyBox: {
        paddingTop: '4px',
        paddingBottom: '4px',
        position: 'sticky',
        bottom: '-4px',
        left: 0,
        right: 0,
        backgroundColor: palette.neutral.neutral1,
        width: '100%',
        zIndex: 1,
    },
}));
