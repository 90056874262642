import React, { ReactNode } from 'react';
import MaskedInput, { Mask } from 'react-text-mask';

import InputField from './InputField';

export interface Input {
    onChange: (value: number | string) => void;
    //eslint-disable-next-line
    onBlur?: (e: any) => void;
    value?: string;
    placeholder?: string;
    isDisabled?: boolean;
    isValidationError?: boolean;
    errorText?: string;
    size?: 'small' | 'medium';
    postfix?: ReactNode | string;
    isHidden?: boolean;
    fieldType?: string;
    mask?: Mask | ((value: string) => Mask);
}

export interface MaskedInputProps {
    mask: Mask | ((value: string) => Mask);
    formatOnBlur?: (value: string) => string;
}

export default function MaskedInputField({
    mask,
    formatOnBlur,
    ...props
}: MaskedInputProps & Input) {
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (formatOnBlur && e.target.value) {
            const formattedValue = formatOnBlur(e.target.value);
            props.onChange(formattedValue);
        }

        if (props.onBlur) {
            props.onBlur(e);
        }
    };

    return (
        <MaskedInput
            guide={true}
            showMask={false}
            mask={mask}
            render={(ref, maskedProps) => {
                return (
                    <InputField
                        ref={ref}
                        {...maskedProps}
                        {...props}
                        onBlur={formatOnBlur ? handleBlur : props.onBlur}
                    />
                );
            }}
        />
    );
}
