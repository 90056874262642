export function required(value: string) {
    if (value === null || value === undefined || value === '') {
        return 'This field is required';
    }
    return undefined;
}
export function requiredWithMessage(message: string) {
    return function (value: string) {
        if (value === null || value === undefined || value === '' || value.length === 0) {
            return message;
        }
        return undefined;
    };
}

export function notMoreThan(max: number) {
    return function (value: string) {
        if (value && Number(value) > +max) {
            return `Must be less than ${max}`;
        }
        return undefined;
    };
}
export function isNumber(value: number) {
    if (value && isNaN(Number(value))) {
        return 'Must be a number';
    }
    return undefined;
}

export function isEmail(value: string) {
    if (!value) {
        return undefined;
    }
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!emailRegex.test(value)) {
        return 'Please provide a correct email address';
    }

    return undefined;
}

export function minLength(min: number) {
    return function (value: string) {
        if (value && value.length < min) {
            return `Must be ${min} characters or more`;
        }
        return undefined;
    };
}
export function maxLength(max: number) {
    return function (value: string) {
        if (value && value.length > max) {
            return `Maximum  ${max} characters field length is supported`;
        }
        return undefined;
    };
}

export function isMapLink(value: string): string | undefined {
    if (!value?.trim()) {
        return 'Map link or embed code is required.';
    }

    const isValidAcresLink = /^https:\/\/(www\.)?acres\.com\/.+/i.test(value.trim());
    const isValidAcresIframe = /<iframe[^>]+src="https:\/\/(www\.)?acres\.com\/[^"]+"[^>]*>/i.test(
        value.trim(),
    );
    const isValidPageLink = /^https:\/\/acres\.page\.link\/.+/i.test(value.trim());

    if (!isValidAcresLink && !isValidAcresIframe && !isValidPageLink) {
        return 'Please provide a valid Acres.com map link, page link, or iframe embed code.';
    }

    return undefined;
}

export function maxLengthForArrayField(maxLength: number) {
    return (value: string[]) => {
        if (value && value.some((i) => i.length > maxLength)) {
            return `Must be ${maxLength} or less`;
        }
    };
}

export function minLengthForArrayField(minLength: number) {
    return (value: string[]) => {
        if (value && value.some((i) => i.length < minLength)) {
            return `Must be ${minLength} or more`;
        }
    };
}

export function maxLengthOfArray(maxLength: number) {
    return (value: string[]) => {
        if (value && value.length > maxLength) {
            return `Maximum ${maxLength} items allowed`;
        }
    };
}

export function isNumberArray(value: string[]) {
    if (value && value.some((i) => isNaN(Number(i)))) {
        return 'All items must be numbers';
    }
}

export function minLengthForArray(minLength: number) {
    return (value: string[]) => {
        if (value && value.length < minLength) {
            return `Minimum ${minLength} items required`;
        }
    };
}
export function isURL(value: string) {
    if (!/^https?:\/\//i.test(value)) {
        value = 'https://' + value;
    }

    try {
        new URL(value);
        return undefined;
    } catch {
        return 'Please provide a valid URL';
    }
}

export function matchesPattern(pattern: RegExp, errorMessage: string) {
    return function (value: string) {
        if (value && !pattern.test(value)) {
            return errorMessage;
        }
        return undefined;
    };
}
export function isPositiveNumber(value: string) {
    if (value && (isNaN(Number(value)) || Number(value) <= 0)) {
        return 'Must be a positive number';
    }
    return undefined;
}

export function isGreaterThan(otherField: string, errorMessage: string) {
    return function (value: string, allValues: Record<string, string>) {
        const currentValue = Number(value);
        const otherFieldValue = Number(allValues?.[otherField]);

        if (!isNaN(currentValue) && !isNaN(otherFieldValue) && currentValue < otherFieldValue) {
            return errorMessage;
        }

        return undefined;
    };
}

export function isLessThan(otherField: string, errorMessage: string) {
    return function (value: string, allValues: Record<string, string>) {
        const currentValue = Number(value);
        const otherFieldValue = Number(allValues?.[otherField]);
        if (!isNaN(currentValue) && !isNaN(otherFieldValue) && currentValue > otherFieldValue) {
            return errorMessage;
        }

        return undefined;
    };
}
export function isDateLessOrEqualThan(otherField: string, errorMessage: string) {
    return function (value: string, allValues: Record<string, string>) {
        const currentValue = new Date(value);
        const otherFieldValue = new Date(allValues?.[otherField]);
        if (currentValue > otherFieldValue) {
            return errorMessage;
        }

        return undefined;
    };
}

export function isDateGreaterOrEqualThan(otherField: string, errorMessage: string) {
    return function (value: string, allValues: Record<string, string>) {
        const currentValue = new Date(value);
        const otherFieldValue = new Date(allValues?.[otherField]);
        if (currentValue < otherFieldValue) {
            return errorMessage;
        }

        return undefined;
    };
}

export function isDateGreaterThanToday(errorMessage: string) {
    return function (value: string) {
        if (!value) {
            return undefined;
        }
        const currentValue = new Date(value);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        if (currentValue < today) {
            return errorMessage;
        }

        return undefined;
    };
}
export function isDateLessThanToday(errorMessage: string) {
    return function (value: string) {
        if (!value) {
            return undefined;
        }
        const currentValue = new Date(value);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        if (currentValue > today) {
            return errorMessage;
        }

        return undefined;
    };
}
export function OnlyDigitsAndSpecialChars(errorMessage: string) {
    return function (value: string) {
        if (value && !/^[0-9!@#$%^&*)(+=._-]+$/.test(value)) {
            return errorMessage;
        }
        return undefined;
    };
}
// type Validation =
//     | ReturnType<typeof required>
//     | ReturnType<typeof isNumber>
//     | ReturnType<typeof minLength>;

/**
 * @TODO: Implement type like Validation
 * @param validators
 */
export const composeValidators =
    // TODO recollect type for validators


        (
            ...validators: // eslint-disable-next-line @typescript-eslint/no-explicit-any
            any[]
        ) =>
        (value: string, allValues: Record<string, string>) =>
            validators.reduce(
                (error, validator) => error || validator(value, allValues),
                undefined,
            );
