import React from 'react';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Grid } from '@mui/material';

import useHandleSubmitRTK from '../../../hooks/useHandleSubmit';

import {
    useUpdateNotificationsSettingsMutation,
    useUpdateUserNotificationsSettingsMutation,
} from '../../../api/notifications/notificationAPI';
import Loader from '../../../design/BaseLoader';
import SubmitButton from '../../../design/Buttons/SubmitButton';
import GenericFormInput from '../../../design/FormFields/GenericFormInput';
import GenericTable, { Table } from '../../../design/GenericTable/GenericTable';
import Paths from '../../../router/paths';
import { EmailDigestSettings, SettingsForNotification } from './NotificationsSettings';

interface OwnProps {
    organizationId?: string;
    notificationsData: {
        notificationSettings: SettingsForNotification[];
        emailDigestSettings: EmailDigestSettings;
    };
    isLoading: boolean;
}

export default function NotificationsSettingsList({
    organizationId,
    notificationsData,
    isLoading,
}: OwnProps) {
    const navigate = useNavigate();

    const { handleSubmit: handleUpdate, isLoading: isUpdateLoading } = useHandleSubmitRTK({
        useRtkHook: useUpdateNotificationsSettingsMutation,
        successMessage: 'Settings updated successfully',
    });

    const {
        handleSubmit: handleUserNotificationSettingsUpdate,
        isLoading: isUserNotificationSettingsUpdating,
    } = useHandleSubmitRTK({
        useRtkHook: useUpdateUserNotificationsSettingsMutation,
        successMessage: 'Settings updated successfully',
    });

    const handleSubmit = ({
        emailDigestSettings,
        ...values
    }: // eslint-disable-next-line
    any) => {
        const valueForSubmit = {
            emailDigestSettings: emailDigestSettings,
            notificationSettings: convertToObjectArray(values),
        };
        if (organizationId) {
            handleUpdate({
                id: organizationId,
                data: valueForSubmit,
            });
            return;
        }
        handleUserNotificationSettingsUpdate({
            data: valueForSubmit,
        });
    };

    function initialValues() {
        const notificationSettings =
            notificationsData?.notificationSettings &&
            convertFromArrayToObject(notificationsData?.notificationSettings);

        return {
            emailDigestSettings: notificationsData?.emailDigestSettings,
            ...notificationSettings,
        };
    }

    if (isLoading) {
        return (
            <Grid
                width={'100%'}
                height={'100vh'}
                container
                alignItems={'center'}
                justifyContent={'center'}>
                <Loader />
            </Grid>
        );
    }

    return (
        <Form onSubmit={handleSubmit} initialValues={initialValues()}>
            {({ handleSubmit, pristine }) => (
                <form onSubmit={handleSubmit}>
                    <GenericTable
                        data={notificationsData && transformData(notificationsData)}
                        isLoading={isLoading}
                        tableConfig={tableConfig}
                    />
                    <Grid container pt={2} width={'100%'} justifyContent={'flex-end'} columnGap={4}>
                        <Button
                            variant={'outlined'}
                            onClick={() => navigate(`/${Paths.settings}`)}
                            color={'secondary'}>
                            Cancel
                        </Button>
                        <SubmitButton
                            isDisabled={pristine}
                            variant={'contained'}
                            isLoading={isUpdateLoading || isUserNotificationSettingsUpdating}
                        />
                    </Grid>
                </form>
            )}
        </Form>
    );
}
const tableConfig: Table<SettingsForNotification | EmailDigestSettings> = {
    columns: [
        {
            header: {
                label: 'Notifications',
                sortable: false,
            },
            cellRender: (setting) => setting.name,
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Enable',
                sortable: false,
            },
            cellRender: (setting) => (
                <GenericFormInput
                    scheme={'full'}
                    name={
                        setting.id === 'EMAIL_DIGEST'
                            ? 'emailDigestSettings.emailDigestEnabled'
                            : `${setting.id}.enabled`
                    }
                    size={'small'}
                    type={'switch'}
                />
            ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Description',
                sortable: false,
            },
            cellRender: (setting) =>
                setting.id === 'EMAIL_DIGEST' ? (
                    <Box p={2}>
                        <GenericFormInput
                            label={'Select Digest Frequency'}
                            name={`emailDigestSettings.emailDigestFrequency`}
                            scheme={'full'}
                            type={'radio'}
                            options={emailDigestOptions}
                        />
                    </Box>
                ) : (
                    'description' in setting && setting.description
                ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Channel',
                sortable: false,
            },
            cellRender: (setting) =>
                setting.id !== 'EMAIL_DIGEST' && (
                    <GenericFormInput
                        name={`${setting.id}.channels`}
                        type={'checkbox'}
                        options={channelOptions}
                    />
                ),
            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
        {
            header: {
                label: 'Frequency',
                sortable: false,
            },

            cellRender: (setting) =>
                setting.id !== 'EMAIL_DIGEST' && (
                    <Box width={'100%'} display={'flex'} flexDirection={'column'}>
                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            columnGap={3}>
                            First Notification
                            <GenericFormInput
                                name={`${setting.id}.frequency.firstNotificationDaysBefore`}
                                size={'small'}
                                scheme={'full'}
                                type={'select'}
                                fullWidth={true}
                                options={frequencyOptions}
                            />
                        </Box>
                        <Box
                            display={'flex'}
                            columnGap={3}
                            alignItems={'center'}
                            justifyContent={'space-between'}>
                            Second Notification
                            <GenericFormInput
                                name={`${setting.id}.frequency.secondNotificationDaysBefore`}
                                size={'small'}
                                scheme={'full'}
                                type={'select'}
                                fullWidth={true}
                                options={frequencyOptions}
                            />
                        </Box>
                    </Box>
                ),

            format: { align: 'left', color: { color: 'primary' } },
            width: 'auto',
        },
    ],
    pageable: false,
    multiselect: false,
    expandable: false,
};

function convertFromArrayToObject(inputArray: SettingsForNotification[]) {
    const resultObject = {};
    if (inputArray?.length) {
        for (const obj of inputArray) {
            const { id, name, enabled, channels, frequency } = obj;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            resultObject[id] = { id, name, enabled, channels, frequency };
        }
        return resultObject;
    }
}

function convertToObjectArray(inputObject: Record<string, SettingsForNotification>) {
    const resultArray = [];
    for (const [id, properties] of Object.entries(inputObject)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const newObj = { id, ...properties };
        resultArray.push(newObj);
    }
    return resultArray;
}

function transformData(inputData: OwnProps['notificationsData']) {
    const notificationSettings = inputData?.notificationSettings?.map((setting) => ({
        id: setting.id,
        name: setting.name,
        description: setting.description,
        enabled: setting.enabled,
        channels: setting.channels,
        frequency: {
            firstNotificationDaysBefore: setting.frequency.firstNotificationDaysBefore,
            secondNotificationDaysBefore: setting.frequency.secondNotificationDaysBefore,
        },
        organizationId: setting.organizationId,
        default: setting.default,
    }));

    const emailDigestSettings = {
        id: 'EMAIL_DIGEST',
        emailDigestEnabled: inputData?.emailDigestSettings?.emailDigestEnabled,
        emailDigestFrequency: inputData?.emailDigestSettings?.emailDigestFrequency,
        name: 'Email Digest',
    };

    return [...notificationSettings, emailDigestSettings];
}

const channelOptions = [
    {
        id: 'EMAIL',
        name: 'Email',
    },
    {
        id: 'IN_APP',
        name: 'In-app',
    },
];
const emailDigestOptions = [
    {
        id: 'DAILY',
        name: 'Daily',
    },
    {
        id: 'WEEKLY',
        name: 'Weekly',
    },
];
const frequencyOptions = [
    {
        id: '1',
        name: '1 day before',
    },
    {
        id: '7',
        name: '1 week before',
    },
    {
        id: '14',
        name: '2 week before',
    },
    {
        id: '30',
        name: '30 days before',
    },
    {
        id: '60',
        // eslint-disable-next-line max-lines
        name: '60 days before',
    },
    {
        id: '90',
        name: '90 days before',
    },
    {
        id: '180',
        name: '180 days before',
    },
];
