import React from 'react';

import { Box, Grid, Typography } from '@mui/material';

import GenericFormInput from '../../design/FormFields/GenericFormInput';
import { FormConfig } from '../../design/Forms/interfaces';

interface OwnProps {
    formConfig: FormConfig[];
    title?: string;
    gap?: number;
    isHidden?: boolean;
    backgroundColor?: string;
    borderRadius?: string | number;
    padding?: string | number;
    innerPadding?: string | number;
}
export default function FormPage({
    backgroundColor = 'transparent',
    borderRadius,
    padding,
    innerPadding = 1,
    formConfig,
    isHidden,
    gap = 1,
    title,
}: OwnProps) {
    if (isHidden) {
        return null;
    }
    return (
        <Box padding={padding} display={'flex'} flexDirection={'column'} rowGap={2}>
            {title && (
                <Typography variant={'font14'} fontWeight={'medium'}>
                    {title}
                </Typography>
            )}
            <Grid
                container
                alignContent={'center'}
                padding={innerPadding}
                spacing={1}
                rowGap={gap}
                borderRadius={borderRadius}
                bgcolor={backgroundColor}>
                {formConfig.map(({ formField, renderProps }) => {
                    return (
                        <GenericFormInput key={formField.name} {...formField} {...renderProps} />
                    );
                })}
            </Grid>
        </Box>
    );
}
