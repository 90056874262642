import React from 'react';

import {
    Button,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';

import clsx from 'clsx';

export interface RadioOption {
    id: string | boolean | null;
    name: string;
}

export interface RadioRow {
    options: RadioOption[];
    value: string | boolean;
    onChange: (value: string | boolean) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    label?: string;
    errorText?: string;
    isDisabled?: boolean;
    isRow?: boolean;
    name?: string;
    size?: 'third' | 'half' | 'full';
    labelType?: 'small' | 'regular';
}

export default function RadiobuttonRow({
    options,
    value,
    onChange,
    label,
    size = 'full',
    isRow = true,
    errorText,
    name,
    labelType = 'regular',
    isDisabled = false,
}: RadioRow) {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedValue = event.target.value;
        let parsedValue;

        if (selectedValue === 'true') {
            parsedValue = true;
        } else if (selectedValue === 'false') {
            parsedValue = false;
        } else {
            parsedValue = selectedValue;
        }

        onChange(parsedValue);
    };

    return (
        <FormControl fullWidth component="fieldset" error={!!errorText} disabled={isDisabled}>
            {label && <FormLabel component="legend">{label}</FormLabel>}
            <RadioGroup
                id={name}
                row={isRow}
                aria-label="radiogroup"
                name="radiogroup"
                value={value}
                onChange={handleChange}>
                {options.map((option) =>
                    option.id === null ? (
                        <Button
                            sx={{ marginLeft: 1 }}
                            key={option.name}
                            onClick={() => onChange('')}
                            disabled={isDisabled}>
                            {option.name}
                        </Button>
                    ) : (
                        <FormControlLabel
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                width: clsx(
                                    size === 'full' && '100%',
                                    size === 'half' && '50%',
                                    size === 'third' && '33.33%',
                                ),
                            }}
                            key={option.name}
                            value={option.id}
                            control={<Radio />}
                            label={
                                <Typography
                                    variant={labelType === 'small' ? 'font12' : 'font14'}
                                    fontWeight={'regular'}
                                    color={'text.neutral6'}>
                                    {option.name}
                                </Typography>
                            }
                        />
                    ),
                )}
            </RadioGroup>
            {errorText && <FormHelperText>{errorText}</FormHelperText>}
        </FormControl>
    );
}
